import React from 'react';
import styled from 'styled-components';

interface CodeBlockProps {
  text: string;
}

const Container = styled.div`
  font-family: 'Arial', sans-serif;
`;

const Text = styled.span`
  margin-bottom: 10px;
`;

const Code = styled.pre`
  background-color: #f5f5f5;
  padding: 10px;
`;

const CodeBlock: React.FC<CodeBlockProps> = ({ text }) => {
  const renderText = (text: string) => {
    const parts = text.split("```")
    return parts.map((part, index) => {
      if (index % 2 === 0) {
        return <Text key={index}>{part}</Text>;
      } else {
        return <Code key={index}>{part}</Code>;
      }
    });
  };

  return <Container>{renderText(text)}</Container>;
};

export default CodeBlock;
