
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {
  FormContainer, 
} from './styled/various';
import { ProtectedRoute } from './ProtectedRoute';
import { PublicPage } from './PublicPage';
import { AdminPage } from './AdminPage';
import { SearchPage } from './SearchPage';
import { Header } from './Header';
import { AdminVectorModifyRecord } from './AdminVectorModifyRecord';
import { AdminVectorAddRecord } from './AdminVectorAddRecord';
import { Login } from './Login';
import { PasswordChangeForm } from './PasswordChangeForm';
import { PasswordForgotForm } from './PasswordForgotForm';


export const Main = ({ captchaToken }: {captchaToken: string}) => {


    return (
    <FormContainer>
      
      <BrowserRouter> 
        <Header />  
        <Routes> 
          {/* Public routes */}
          <Route path="/" element={<PublicPage captchaToken={captchaToken} /> } /> 
          <Route path="/login" element={<Login /> } /> 
          <Route path="/changePassword/:userId/:tempString" element={<PasswordChangeForm /> } /> 
          <Route path="/forgotPassword" element={<PasswordForgotForm /> } />
          
          {/* Admin routes */}
          <Route path="/admin" element={
            <ProtectedRoute>  
              <AdminPage />
            </ProtectedRoute>     
            } 
          />
          
          <Route path="/admin/vector/modify/:vectorId" element={
            <ProtectedRoute>  
              <AdminVectorModifyRecord />
            </ProtectedRoute>              
            } 
          />

          <Route path="/admin/vector/add" element={
            <ProtectedRoute>  
              <AdminVectorAddRecord />
            </ProtectedRoute>              
            } 
          />          
          <Route path="/admin/search/:query" element={
            <ProtectedRoute>  
              <SearchPage />
            </ProtectedRoute>              
            } 
          />

          <Route path="/bleron" element={
            <ProtectedRoute>
              <AdminPage />
            </ProtectedRoute>
          }/>

        </Routes>  
      </BrowserRouter>  
 
    </FormContainer>);
};