import * as React from "react";
import {
  Flex,
  Box,
  Link,
  Text
} from "rebass";
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import useGetProjectConversations from "../hooks/admin/useGetProjectConversations";
import useGetConversationAiResponses from "../hooks/admin/useGetConversationAiResponses";
import { MainButton } from "./MainButton";
import Pagination from './Pagination';
import { SearchField } from './Search';
import { backendUrl, projectName } from '../i18n';
import api from '../common/api';
import { AxiosResponse } from 'axios';


type VectorSearchMetadataType = {
  metadata: {
    [key: string]: any
  }
}

export const AdminPage = () => {
  const navigate = useNavigate();

  const [conversationId, setConversationId] = React.useState<string>('');
  const [pageNumber, setPageNumber] = React.useState<number>(1);


  const { data: projectConversations} = useGetProjectConversations(projectName, pageNumber);
  const { data: conversationAiResponses, isLoading: isLoadingConversation } = useGetConversationAiResponses(conversationId); 
  

  const handleSearch = (searchString: string) => {
    // Redirect to the search page, which takes the search string as a parameter and executes the search on load
    navigate(`/admin/search/${searchString}`);
  };

  // This is used to obtain the Id of vector, using the URL as metadata
  const searchVectorMetadataMutation = useMutation<AxiosResponse<any, any>, Error, VectorSearchMetadataType>(async ({ metadata }) => {
    const response = await api.post(`${backendUrl}/${projectName}/vectors/search/metadata`, {
      metadata
    });
    return response;
  }, {
    onSuccess: (response) => {
      const vectorIdForUrl = response.data.results[0].id;
      navigate(`/admin/vector/modify/${vectorIdForUrl}`);
    },
  });

  const { mutate: mutationSearchMetadata, isLoading: isLoadingSearchVectorMetadata } = searchVectorMetadataMutation;

  const handleGetUrlVectorId = async ({ url } : { url: string}) => {
    const metadata = {
      url
    };
    mutationSearchMetadata({ metadata });
  }

  return (
    <>
      <Flex justifyContent={'space-between'}>
        <h1>Admin page</h1>
        <SearchField onSearch={handleSearch} isLoading={false} />
      </Flex>


      {isLoadingConversation || isLoadingSearchVectorMetadata ? (
        <div>Loading...</div>
        ) : (
          <Flex>
              <Flex flexDirection={'column'}>
                {projectConversations?.conversations && projectConversations.conversations.map((row, index) => {
                  return (  
                    <MainButton 
                      key={`adminLeftMenu${index.toString()}`}
                      title={row.title}
                      conversationId={row.conversationId}
                      currentConversationId={conversationId}
                      fontSize='14px'
                      onClick={() => {                        
                        setConversationId(row.conversationId);
                      }}                            
                    /> 
                  )
                })}
                <Pagination
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  totalPages={projectConversations?.totalPages || 1}
                />
              </Flex>


              <Flex width={3 / 4} flexDirection={'column'}>

                  {conversationAiResponses && conversationAiResponses.map((row, index) => {
                    return (
                      <Flex flexDirection={'column'} key={index}>
                        <Box key={`question${index.toString()}`}><b>Question {index+1}:</b> {row.question}</Box>
                        <Box key={`response${index.toString()}`}><b>Answer {index+1}:</b> {row.chainResponse}</Box>
                        {row.sources[0] && 
                            <Flex flexDirection={'column'}>
                              <br />
                              <Text><b>Main source for Answer {index+1}:</b> </Text>
                              <Link
                                key={`source${index.toString()}`}
                                style={{ textDecoration: "none" }}
                                // href={`admin/vector/modify/${row.sources[0].url}`}
                                href={'#'}
                                p={2}
                                pl={0}
                                pt={1}
                                onClick={() => handleGetUrlVectorId({ url: row.sources[0].url })}
                              >
                                {row.sources[0].url}
                              </Link>
                              <br />
                            </Flex>
                        }
                      </Flex>
                    )
                  })}

              </Flex> 


        </Flex>      
      )}
    </>
  );
};